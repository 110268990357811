.hero-section {
    width: 100%;
    height: 800px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  
  .hero-content {
    max-width: 80%;
    margin: 0 auto;
  }
  .lead {
    max-width: 500px;
  }
  .hero-heading {
    font-size: 100px;
    line-height: 1.2;
    font-weight: bold;
  }
  
  .hero-description {
    font-size: 36px;
    margin: 20px 0;
  }
  
  .hero-buttons {
    margin-top: 40px;
  }
  
  .btn-transparent {
    padding: 10px 20px;
    font-size: 18px;
    border: 2px solid white;
    background: transparent;
    color: white;
    cursor: pointer;
    margin-right: 15px;
    transition: background 0.3s ease;
  }
  
  .btn-transparent:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .btn-solid {
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    background-color: white;
    color: black;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .btn-solid:hover {
    background-color: #f0f0f0;
  }
  